<template>
	<div v-if="dialogVisible">
		<el-dialog title="选择" :visible.sync="dialogVisible" width="85%" :before-close="resetdata">			
				
			<div style="width: 100%;height: 65vh;display: flex;align-items: center;">
				
				
				<div style="width: 70%;height: 100%;overflow-y: auto;padding: 10px;">
					<div class="headerbox">
						<div class="headermenu" :class="leftmenuindex==index?'headermenu_active':''" 
						v-for="(item,index) in leftmenu" :key="index" @click="leftmenuindex=index">{{item}}</div>
						
						<!-- <div class="kong"></div> -->
					</div>
					<div class="leftcont" style="border-top: 1px solid #eee;display: flex;">
						
						
						<div style="width: 35%;height: 100%;overflow-y: auto;">
							<el-tree v-show="!filterText" class="filter-tree" :props="props" :load="loadNode" ref="tree" lazy highlight-current @node-click="change">
							</el-tree>
							
							<!-- <div v-show="filterText" style="display: flex;flex-direction: column;padding: 0 10px;">
								<div style="margin-bottom: 5px;cursor: pointer;font-size: 14px;color: #666;" :class="searchid==item.id?'active':''" v-for="(item,index) in searchdata" :key="index" @click="change(item)">{{item.name}} </div>
							</div> -->
						</div>
						
						<div style="width: 65%;">
							<div style="width: 100%;padding: 0 10px;margin-bottom: 10px;">
								<el-input placeholder="输入名称进行搜索" size="small" v-model="searchname" suffix-icon="el-icon-search" @input="changeinput">
								</el-input>
							</div>
							<div style="height: 92.3%;">
								<MyTable ref="mytable" :tableData="tableData" :tableHeader="tableHeader" :showselect="true" :btnwidth="100" :showcz="false"
									@handleSelectionChange="selectchange" :showpages="false">
									
								</MyTable>
							</div>
							
						</div>
					</div>
					
				</div>
				<div style="width: 30%;height: 100%;overflow-y: auto;padding: 10px;">
					<div class="headerbox">
						<div class="headermenu headermenu_active">已选用户({{selectuser.length}})</div>
						
					</div>
					<div class="leftcont" style="border-top: 1px solid #eee;">
						<div style="width: 100%;padding: 0 10px;">
							<div style="display: flex;align-items: center;justify-content: flex-end; width: 100%;padding: 0 10px;margin-bottom: 10px;height: 32px;border-bottom: 1px solid #eee;">
								<el-tooltip class="item" effect="light" content="删除全部" placement="top-start">
								    <i class="el-icon-delete czicon"  @click="clearall"></i>
								</el-tooltip>
								<el-tooltip class="item" effect="light" content="回到初始状态" placement="top-start">
								    <i class="el-icon-refresh-left czicon" style="margin-left: 20px;"  @click="clearall"></i>
								</el-tooltip>
								
							</div>
						</div>
						
						
						<div class="linebox">
							<div class="itemname" v-for="(item,index) in this.selectuser" :key="index">
								<div class="itemcont">{{item.name}}</div>
								<i class="el-icon-close itemicon" @click="deleteselect(index)"></i>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<div style="width: 100%;display: flex;align-items: center;justify-content: flex-end;">				
				<el-button size='small' style="font-zise:14px;" type="" @click="dialogVisible = false">关闭</el-button>	
				<el-button size='small' style="font-zise:14px;" type="primary" @click="subimt">确定选择</el-button>
			</div>
		</el-dialog>
	</div>
	
</template>

<script>
	export default {
		data() {
			return {
				searchname:'',
				isdanxuan:false,//是否为单选
				leftmenu:['组织架构'],
				leftmenuindex:0,
				dialogVisible: false,
				data: [],
				defaultProps: {
					children: 'children',
					label: 'name'
				},
				props: {
					label: 'name',
					children: 'zones',
					isLeaf: 'leaf'
				},
				selectdata: [],
				filterText: '',
				tableData: [],
				tableHeader: [
					{
					    name: '名称',
					    prop: 'name',
					    width: ''
					},
					{
					    name: '登录名',
					    prop: 'username',
					    width: ''
					},
					{
					    name: '联系电话',
					    prop: 'phone'
					},
					{
						name:'性别',
						prop:'sex_name'
					}
				],
				optionmz:[],//民族
				selectnow:[],//当前架构下的选中数据
				selectuser:[],//总的选中数据
			}
		},
		mounted() {
			// this.getuser()
			// this.getoptionmz()//民族
		},
		watch: {			
			filterText(val) {
			    if(val){
			    	this.$post({
			    		url: '/api/organizational/quan',
			    		params: {
			    			order_by: '',
			    			sort: '',
			    			type: 2,
			    			name:val,
			    			p: 1,
			    			size: 9999
			    		}
			    	}).then((res) => {
			    		let list = res.list.map((item) => {
			    			return {
			    				name: item.name,
			    				id: item.id,
			    				// disabled:item.xia,
			    				isLeaf: !item.xia,
			    				ischeck:false
			    			}
			    		})
			    		this.searchdata = list
			    	})
			    }
			}
		},
		methods: {
			changeinput(e){
				console.log(e)
				this.$post({
					url: '/api/Login/userlist',
					params: {
						name:e
					}
				}).then((res) => {
					
					res.list.forEach((item,index)=>{
						item.sex_name = item.sex==1?'男':'女'
						this.optionmz.forEach((item2,index2)=>{
							if(item.nation_id == item2.id){
								item.nation_name = item2.name
							}							
						})
					})
					this.tableData = res.list
					//回显打勾的数据
					let ids = this.selectuser.map((item)=>{ return item.id })
					let sdata=[]
					this.tableData.forEach(row => {
						if(ids.indexOf(row.id) >= 0){
						  sdata.push(row)
						}
					})
					this.$refs.mytable.selectdata = sdata
					this.$refs.mytable.setgouxuan()
				})
			},
			getoptionmz(){
				this.$post({
					url: '/api/nation/index',
					params: {
						order_by:'',
						sort:'',
						p:1,
						size:999
					}
				}).then((res) => {
					this.optionmz = res.data.list
					this.getlist()
				})
			},
			selectchange(e){				
				//切换架构 合并选中的数据
				this.selectuser = this.selectuser.concat(e)
				this.selectuser = Array.from(new Set(this.selectuser.map(user => user.id))).map(id => this.selectuser.find(user => user.id === id))
				
			},
			loadNode(node, resolve) {
				if (node.level === 0) {		
					this.$post({
						url: '/api/organizational/index',
						params: {
							order_by: '',
							sort: '',
							type:1,
							pid:0,
							p: 1,
							size: 9999
						}
					}).then((res) => {
						this.searchid = res.list[0].id
						this.getuser()
						let list = res.list.map((item)=>{ return {name:item.name,id:item.id,isLeaf:!item.xia}})						
						return resolve(list)
					})					
				}else{
					this.$post({
						url: '/api/organizational/index',
						params: {
							order_by: '',
							sort: '',
							type:1,
							pid:node.data.id,
							p: 1,
							size: 9999
						}
					}).then((res) => {
						let list = res.list.map((item)=>{ return {name:item.name,id:item.id,isLeaf:!item.xia}})
						return resolve(list)
					})	
				}				
				
			},
			huixian(){//设置回显
				this.$nextTick(()=>{
					this.$refs.mytree.setCheckedNodes(this.selectdata)
				})
				
			},
			resetdata(){
				this.tableData = []
				this.selectuser = []
				this.dialogVisible = false
			},
			subimt(){
				console.log(this.selectuser)
				if(this.isdanxuan&&this.selectuser.length>1){
					this.$message.warning('只能选择一人')
					return
				}
				this.$emit('selectedry',this.selectuser)
				this.resetdata()
			},
			getuser(){
				this.$post({
					url: '/api/user/index',
					params: {
						order_by: '',
						sort: '',
						organizational_id:this.searchid,
						p: 1,
						size: 9999
					}
				}).then((res) => {
					res.list.forEach((item,index)=>{
						item.sex_name = item.sex==1?'男':'女'
						this.optionmz.forEach((item2,index2)=>{
							if(item.nation_id == item2.id){
								item.nation_name = item2.name
							}							
						})
					})
					this.tableData = res.list
					
					console.log(this.selectuser)
					//回显打勾的数据
					let ids = this.selectuser.map((item)=>{ return item.id })
					let sdata=[]
					this.tableData.forEach(row => {
						if(ids.indexOf(row.id) >= 0){
						  sdata.push(row)
						}
					})
					this.$refs.mytable.selectdata = sdata
					this.$refs.mytable.setgouxuan()
				})
			},
			change(a, b) {		
				if(a&&a.id){
					this.searchid =a.id
				}
				this.getuser()
			},
			clearall(){
				this.selectuser = []
				this.change()
			},
			deleteselect(index){
				this.selectuser.splice(index,1)
				this.change()
			},
			filterNode(value, data) {
				if (!value) return true;
				return data.label.indexOf(value) !== -1;
			}
		}
	}
</script>

<style lang="scss" scoped>
	.headerbox{
		display: flex;
		align-items: center;
	}
	.headermenu{
		margin-right: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0 8px;
		border-right: 1px solid #eee;
		border-top: 1px solid #eee;
		border-left: 1px solid #eee;
		border-top-right-radius: 5px;
		border-top-left-radius: 5px;
		font-size: 14px;
		cursor: pointer;
		background: #f5f5f5;
		color: #5c5c5c;
	}
	.headermenu_active{
		color: rgb(63,158,255);
		background-color: #fff;
		position: relative;
	}
	.headermenu_active:after{
		position: absolute;
		content: '';
		width: 100%;
		height: 4px;
		bottom: -2px;
		background-color: #fff;
	}
	.headermenu,.kong{
		height: 24px;
	}
	.kong{
		flex: 1;
		border-bottom: 1px solid #eee;
	}
	.leftcont{
		padding-top: 10px;
		height: 550px;
		overflow-y: auto;
		border: 1px solid #eee;
		border-top: 0;
		
	}
	.czicon{
		cursor: pointer
	}
	.czicon:hover{
		color: rgb(63,158,255);
	}
	.linebox{
		width: 100%;
		padding: 0 10px;
	}
	.itemname{
		height: 32px;
		font-size: 14px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		cursor: pointer;
		padding: 0 5px;
	}
	.itemcont{
		
	}
	.itemicon{
		display: none;
	}
	.itemname:hover .itemicon{
		display: block;
	}
	.itemicon:hover{
		color: rgb(63,158,255);
	}
	.itemname:hover{
		background: #f5f5f5;
	}
	::v-deep .el-input--small .el-input__icon{
		color: #333;
		font-weight: bold;
	}
	::v-deep .el-dialog__header {
		border-bottom: 1px solid #eee;
	}

	::v-deep .el-dialog__footer {
		border-top: 1px solid #eee;
	}
	::v-deep .el-dialog__body{
		padding-top: 10px;
	}
	::v-deep .el-tabs--border-card{
		height: 100%;
	}
	::v-deep .el-tabs--border-card{
		box-shadow: 0 0 0 #fff;
	}
	::v-deep .el-tabs__header{
		background-color: #fff;
	}
	::v-deep .el-dialog{
		margin: 0 !important;
	}
</style>